import { utils } from "../modules/_utils";

let all_assumptions = {
  /*
    Field names for user fields must match a field in the solicitation.services.opts2 object.
    The field name is used to look up the value in the solicitation.services.opts2 object.
    Field names for system fields do not have to match a field in the solicitation.services.opts2 object.
    System fields are used for us to provide our own assumptions if the user does not provide a value.
    Warning fields are used to display a warning message if the user provides a specific value.
    The global section is not currently used.
*/

  global: {
    require_total: true,
    require_pricing_model: true,
    allow_additional_info: true,
    allow_attachments: true,
    allow_comments: true,
    allow_multiple_responses: true,
  },
  services: {
    credit_monitoring: {
      fields: [
        //   {
        //     "name": "",
        //     "jurisdiction": "United States",
        //     "total": 0,
        //     "number_of_bureaus": [
        //         "1"
        //     ],
        //     "years_of_credit_monitoring": [
        //         "1"
        //     ],
        //     "pricing_type": [
        //         "fixed_fee",
        //         "redemption"
        //     ],
        //     "number_of_affected_individuals": "1234",
        //     "adults": "12",
        //     "minors": "13"
        // },
        {
          name: "number_of_affected_individuals",
          title: "Affected Individuals",
          type: "user",
        },
        {
          name: "adults",
          title: "Adults",
          type: "user",
        },
        {
          name: "minors",
          title: "Minors",
          type: "user",
        },
        {
          name: "jurisdiction",
          title: "Jurisdiction",
          type: "user",
        },
        {
          name: "number_of_bureaus",
          title: "Number of Bureaus",
          description:
            "The requestor has asked for separate quotes for each of the requested number of bureaus.",
          type: "list",
          label: "Bureuas",
          scenario: true,
        },
        {
          name: "years_of_credit_monitoring",
          title: "Years of Credit Monitoring",
          type: "list",
          label: "Years",
          description:
            "The requestor has asked for separate quotes for each of the requested number of years.",
          scenario: true,
        },
        {
          name: "pricing_type",
          title: "Pricing Type",
          type: "list",
          description:
            "The requestor has asked for separate quotes for each of the requested pricing types.",
          scenario: true,
        },
        {
          name: "required_language",
          title: "Languages",
          // label: "Value",
          type: "list",
        },
      ],
    },
    mailings: {
      fields: [
        {
          name: "number_of_affected_individuals",
          title: "Affected Individuals",
          type: "user",
        },

        { name: "forms_of_letter", title: "Forms of Letter", type: "user" },
        {
          name: "double_sided_pages",
          title: "Double Sided Pages",
          type: "user",
        },
        {
          name: "return_mail",
          title: "Return Mail",
          type: "user",
          data_type: "boolean",
          scenario: true,
        },
        {
          name: "ncoa",
          title: "NCOA",
          type: "user",
          data_type: "boolean",
          scenario: true,
        },
        {
          name: "text_color",
          title: "Print Color",
          type: "user",
        },
        {
          name: "mailing_jurisdictions",
          title: "Mailing Breakdown",
          type: "text",
        },
        {
          name: "required_language",
          title: "Languages",
          // label: "Value",
          type: "list",
        },

        {
          name: "reporting_requirements",
          title: "Reporting",
          // label: "Value",
          type: "list",
        },
        // {
        //   name: "data_compression",
        //   title: "Data Compression",
        //   label: "Value",
        //   type: "user",
        // },
        // {
        //   name: "expansion_rate",
        //   title: "Data Expansion Rate",
        //   label: "x",
        //   type: "user",
        // },
        // {
        //   name: "docs_per_gb",
        //   title: "Documents per GB",
        //   label: "docs/GB",
        //   type: "system",
        //   value: 5000,
        // },
        // {
        //   name: "document_count",
        //   title: "Number of Documents",
        //   label: "Documents",
        //   type: "user",
        // },

        // {
        //   name: "hours_per_week_for_status_calls",
        //   title: "Project Management",
        //   label: "Hours/wk",
        //   type: "user",
        // },
        // {
        //   name: "project_duration",
        //   title: "Project Duration",
        //   label: "months",
        //   type: "system",
        //   value: 1,
        // },
      ],
    },
    data_mining: {
      fields: [
        // {
        //   name: "require_total",
        //   title: "Total",
        //   label: "Value",
        //   type: "warning",
        //   message: () =>
        //     `Please provide an estimated total price for this service.`,
        // },
        // {
        //   name: "require_pricing_model",
        //   title: "Pricing Model",
        //   label: "Value",
        //   type: "warning",
        //   message: () =>
        //     `The requestor has asked that you provide a pricing model to make it easier to compare quotes.`,
        // },
        // {
        //   name: "multiple_quotes",
        //   title: "Allow mixed quotes",
        //   label: "Value",
        //   type: "warning",
        //   message: (e, service) => {
        //     return `The requestor has asked that you only provide your ${utils.cleanUpKeys(
        //       service
        //     )} pricing on this tab. Please do not mix Data Mining and Review services.`;
        //   },
        // },
        {
          name: "contingent_discounts",
          title: "Discounts",
          label: "Value",
          type: "warning",
          message: (e, service) => {
            return `If any discounts are contingent upon winning both Data Mining and Review, please provide those assumptions as part of the Review quote.`;
          },
        },
        {
          name: "offshore_resources",
          title: "Staffing Preference",
          label: "Value",
          type: "text",
          value: (value) => {
            if (value === "onshore") {
              return `The requestor prefers using onshore resources for this project. Please do not quote offshore staffing.`;
            } else if (value === "offshore") {
              return `The requestor prefers using offshore resources for this project. Please do not quote onshore staffing.`;
            } else {
              return `No onshore or offshore preferences.  Please quote the most cost effective solution.`;
            }
          },
          description:
            "If the requestor does not specify a preference, be sure to include the staffing location in Additional Information area.",
        },
        { name: "data_size", title: "Data Volume", label: " GB", type: "user" },
        {
          name: "data_compression",
          title: "Data Compression",
          label: "Value",
          type: "user",
        },
        {
          name: "expansion_rate",
          title: "Data Expansion Rate",
          label: "x",
          type: "user",
        },
        {
          name: "docs_per_gb",
          title: "Documents per GB",
          label: " docs/GB",
          type: "system",
          value: 5000,
        },
        {
          name: "document_count",
          title: "Number of Documents",
          label: " Documents",
          type: "user",
        },

        {
          name: "hours_per_week_for_status_calls",
          title: "Project Management",
          label: " Hours/wk",
          type: "user",
        },
        // {
        //   name: "project_duration",
        //   title: "Project Duration",
        //   label: "months",
        //   type: "system",
        //   value: 1,
        // },
      ],
    },
    document_review: {
      fields: [
        {
          name: "processed_before",
          title: "Has been processed",
          label: "Value",
          type: "user",
          data_type: "boolean",
        },
        // {
        //   name: "require_total",
        //   title: "Total",
        //   label: "Value",
        //   type: "warning",
        //   message: () =>
        //     `Please provide an estimated total price for this service.`,
        // },
        // {
        //   name: "require_pricing model",
        //   title: "Pricing Model",
        //   label: "Value",
        //   type: "warning",
        //   message: () =>
        //     `The requestor has asked that you provide a pricing model to make it easier to compare quotes.`,
        // },
        // {
        //   name: "multiple_quotes",
        //   title: "Allow mixed quotes",
        //   label: "Value",
        //   type: "warning",
        //   message: (e, service) => {
        //     return `The requestor has asked that you only provide your ${utils.cleanUpKeys(
        //       service
        //     )} pricing on this tab. Please do not mix Data Mining and Review services.`;
        //   },
        // },
        {
          name: "contingent_discounts",
          title: "Discounts",
          label: "Value",
          type: "warning",
          message: () => {
            return `The requestor asked that you provide any contingent discount assumptions in the Additional Information section, NOT in the Estimated Cost or Pricing Model.`;
          },
        },
        {
          name: "offshore_resources",
          title: "Staffing Preference",
          label: "Value",
          type: "text",
          value: (value) => {
            if (value === "onshore") {
              return `The requestor prefers using onshore resources for this project. Please do not quote offshore staffing.`;
            } else if (value === "offshore") {
              return `The requestor prefers using offshore resources for this project. Please do not quote onshore staffing.`;
            } else {
              return `No onshore or offshore preferences.  Please quote the most cost effective solution.`;
            }
          },
          description:
            "If the requestor does not specify a preference, be sure to include the staffing location in Additional Information area.",
        },

        { name: "data_size", title: "Data Volume", label: " GB", type: "user" },
        {
          name: "data_compression",
          title: "Data Compression",
          label: "Value",
          type: "user",
        },
        {
          name: "expansion_rate",
          title: "Data Expansion Rate",
          label: "x",
          type: "user",
        },
        {
          name: "document_count",
          title: "Number of Documents",
          label: " Documents",
          type: "user",
        },
        {
          name: "survival_rate",
          title: "Documents Requiring Manual Review",
          label: "%",
          type: "user",
        },
        {
          name: "hosting_option",
          title: "Hosting Option",
          label: "Value",
          type: "user",
        },

        {
          name: "hours_per_week_for_status_calls",

          title: "Project Management",
          label: " hrs/wk",
          type: "user",
        },
        {
          name: "project_duration",
          title: "Project Duration",
          label: " weeks",
          type: "user",
        },
      ],
    },
    ediscovery_document_review: {
      fields: [
        // {
        //   name: "require_total",
        //   title: "Total",
        //   label: "Value",
        //   type: "warning",
        //   message: () =>
        //     `Please provide an estimated total price for this service.`,
        // },
        // {
        //   name: "require_pricing model",
        //   title: "Pricing Model",
        //   label: "Value",
        //   type: "warning",
        //   message: () =>
        //     `The requestor has asked that you provide a pricing model to make it easier to compare quotes.`,
        // },
        // {
        //   name: "multiple_quotes",
        //   title: "Allow mixed quotes",
        //   label: "Value",
        //   type: "warning",
        //   message: (e, service) => {
        //     return `The requestor has asked that you only provide your ${utils.cleanUpKeys(
        //       service
        //     )} pricing on this tab. Please do not mix Data Mining and Review services.`;
        //   },
        // },
        {
          name: "contingent_discounts",
          title: "Discounts",
          label: "Value",
          type: "warning",
          message: () => {
            return `The requestor asked that you provide any contingent discount assumptions in the Additional Information section, NOT in the Estimated Cost or Pricing Model.`;
          },
        },

        {
          name: "offshore_resources",
          title: "Staffing Preference",
          label: "Value",
          type: "text",
          value: (value) => {
            if (value === "onshore") {
              return `The requestor prefers using onshore resources for this project. Please do not quote offshore staffing.`;
            } else if (value === "offshore") {
              return `The requestor prefers using offshore resources for this project. Please do not quote onshore staffing.`;
            } else {
              return `No onshore or offshore preferences.  Please quote the most cost effective solution.`;
            }
          },
          description:
            "If the requestor does not specify a preference, be sure to include the staffing location in Additional Information area.",
        },

        { name: "data_size", title: "Data Volume", label: "GB", type: "user" },
        {
          name: "data_compression",
          title: "Data Compression",
          label: "Value",
          type: "user",
        },
        {
          name: "expansion_rate",
          title: "Data Expansion Rate",
          label: "x",
          type: "user",
        },
        {
          name: "document_count",
          title: "Number of Documents",
          label: "Documents",
          type: "user",
        },
        {
          name: "survival_rate",
          title: "Survival Rate",
          label: "%",
          type: "user",
        },
        {
          name: "hosting_option",
          title: "Hosting Option",
          label: "Value",
          type: "user",
        },

        {
          name: "hours_per_week_for_status_calls",

          title: "Project Management",
          label: " hrs/wk",
          type: "user",
        },
        {
          name: "project_duration",
          title: "Project Duration",
          label: "weeks",
          type: "user",
        },
        {
          name: "preferred_tools",
          title: "Preferred Tools",
          label: "Value",
          type: "text",
        },
        {
          name: "key_assumptions",
          title: "Key Assumptions",
          label: "Value",
          type: "text",
          message: () =>
            `Please provide any key assumptions that you used to calculate your quote.`,
        },
      ],
    },
    call_center: {
      fields: [
        {
          name: "number_of_affected_individuals",
          title: "Affected Individuals",
          label: "Value",
          type: "user",
        },
        {
          name: "call_duration",
          title: "Call Duration",
          label: " minutes",
          type: "user",
        },
        {
          name: "call_in_rate",
          title: "Call in Rate",
          label: "Value",
          type: "user",
        },
        {
          name: "support_duration",
          title: "Support",
          label: " days",
          type: "user",
        },
        {
          name: "required_language",
          title: "Languages",
          // label: "Value",
          type: "list",
        },
        {
          name: "call_center_location",
          title: "Call Center Location",
          // label: "Value",
          type: "user",
        },
        {
          name: "call_center_coverage",
          title: "Call Center Coverage",
          // label: "Value",
          type: "list",
          description:
            "Please be sure to provide your standard call center operating hours for the requested coverage periods.",
        },
        {
          name: "reporting_requirements",
          title: "Reporting",
          // label: "Value",
          type: "list",
        },

        {
          name: "hours_per_week_for_status_calls",
          title: "Project Management",
          label: "Hours/wk",
          type: "user",
        },
        // {
        //   name: "project_duration",
        //   title: "Project Duration",
        //   label: "months",
        //   type: "system",
        //   value: 1,
        // },
      ],
    },
    emailings: {
      fields: [
        {
          name: "number_of_affected_individuals",
          title: "Affected Individuals",
          label: "Value",
          type: "user",
        },

        {
          name: "required_language",
          title: "Languages",
          // label: "Value",
          type: "list",
        },

        {
          name: "reporting_requirements",
          title: "Reporting",
          // label: "Value",
          type: "list",
        },
      ],
    },
    class_action: {
      fields: [
        {
          name: "number_of_affected_individuals",
          title: "Class Size",
          type: "user",
        },
        {
          name: "gross_settlement_amount",
          title: "Gross Settlement Amount",
          type: "user",
          label: "   USD",
        },
        {
          name: "all_in_or_claims_made",
          title: "All-in or claims made",
          type: "user",
        },
        {
          name: "notice_page_length",
          title: "Notice page length",
          type: "user",
        },
        {
          name: "website_needed",
          title: "Website Needed",
          type: "user",
          data_type: "boolean",
          lineItemFilter: true,
        },
        {
          name: "translation_services",
          title: "Translation Needed",
          type: "user",
          data_type: "boolean",
          lineItemFilter: true,
        },
        {
          name: "one_eight_hundred_number",
          title: "1-800 Number Needed",
          type: "user",
          data_type: "boolean",
          lineItemFilter: true,
        },
        {
          name: "uncashed_funds",
          title: "What will happen to uncashed funds?",
          type: "text",
        },
        {
          name: "disbursement_type",
          title: "Disbursement Types",
          // label: "Value",
          type: "list",
        },
        {
          name: "1099s_and_w2s",
          title: "1099's and/or W2's",
          type: "user",
          lineItemFilter: true,
          // scenario: true,
          data_type: "boolean",
        },
      ],
    },
  },
};
export default all_assumptions;
