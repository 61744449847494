import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Spreadsheet } from "react-spreadsheet";
import "../../css/spreadsheet.css";
import Button from "../../elements/Button";
import Input from "../../components/Input";
import { Icon } from "@iconify/react";
import { set } from "date-fns";
import ComboboxSelector from "../ComboBoxSelector";
import MergedCell from "./SpreadsheetComponents";
import classNames from "classnames";
import Fade from "react-reveal/Fade";
import Tip from "../Tip";
import { read } from "xlsx";
const SpreadsheetComponent = ({
  pricingItems,
  initialLineItems,
  service,
  handlePriceChange,
  handleSetEstimatedPrice,
  option,
  handleDeleteOption,
  name,
  initialDescription,
  handleChangeDescription,
  index,
  scenarios,
  scenarioOptions,
  servicePrice,
  handleDeleteUnusedLineItems,
}) => {
  const [data, setData] = useState([]);
  const [pricing, setPricing] = useState({});
  const [initialDataSet, setInitialDataSet] = useState(false);
  const [initialScenarios, setInitialScenarios] = useState([]);
  const [totalSum, setTotalSum] = useState(servicePrice || 0);
  const [editing, setEditing] = useState(
    initialDescription && initialDescription !== "" ? false : true
  );
  const [description, setDescription] = useState(initialDescription);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [scenariosList, setScenariosList] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [selectedCell, setSelectedCell] = useState({ row: null, col: null });
  const [rowInstructions, setRowInstructions] = useState(null);
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [exclusions, setExclusions] = useState([]);
  const [pricingMenu, setPricingMenu] = useState([]);
  const [fade, setFade] = useState(true);
  const prevDataRef = useRef([]);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    if (
      selectedCell.row &&
      selectedCell.row.range &&
      selectedCell.row.range.start
    ) {
      if (selectedCell.row.range.start.row === 0) {
        setRowInstructions(null);
        // setSelectedCell({ row: null, col: null });
        setSelectedLineItem(null);
        return;
      }
      const rowIndex = selectedCell.row.range.start.row - 1; // Adjust for the header row
      const relatedLineItem = lineItems[rowIndex]?.name || null;
      const instructions =
        lineItems[rowIndex]?.instructions || "No instructions available";
      console.log(
        "Get and store instructions for selected cell",
        instructions,
        relatedLineItem
      );
      setRowInstructions(instructions);
      setSelectedLineItem(relatedLineItem);
    } else {
      setRowInstructions(null);
      // setSelectedCell({ row: null, col: null });
      setSelectedLineItem(null);
    }
  }, [selectedCell, lineItems]);

  // useEffect(() => {
  //   console.log("Initial Scenarios", scenarios);
  //   setInitialScenarios(scenarios);
  // }, [scenarios]);

  // useEffect(() => {
  //   let list = scenarios;
  //   // when a scenario is selected, remove it from the list
  //   // so it can't be selected again
  //   if (selectedScenario) {
  //     console.log("Selected Scenario", selectedScenario);
  //     list = scenariosList.filter((s) => s.name !== selectedScenario);
  //   }
  //   setScenariosList(list);
  // }, [scenarios, selectedScenario]);

  useEffect(() => {
    setPricing(pricingItems);
    console.log("Spreadsheet <PRICING>", pricing);
  }, [pricingItems]);

  useEffect(() => {
    handleDeleteUnusedLineItems(lineItems);
  }, [selectedScenario]);

  useEffect(() => {
    console.log("Scenarios", scenarios);
  }, [scenarios]);

  useEffect(() => {
    console.log("Initial Description", initialDescription);
    if (initialDescription && initialDescription !== "") {
      setSelectedScenario(initialDescription);
    }
  }, [initialDescription]);

  useEffect(() => {
    console.log("Selected scenario", selectedScenario);
    if (selectedScenario === null) return;
    findScenarioByName();
  }, [selectedScenario]);

  const findScenarioByName = () => {
    console.log("Scenarios", scenarios);

    let scenario = scenarios.find((s) => s.name === selectedScenario);
    console.log("Scenario found", scenario);
    let items = scenario?.line_items;
    console.log("Scenario Items", items);
    setLineItems(items);
    setExclusions(scenario?.exclusions || []);
    setPricingMenu(scenario?.pricing_menu || []);
    // handleDeleteUnusedLineItems(items);
  };

  useEffect(() => {
    //this wont work until I add a check
    //to see if the totalSum is different than the lineItem price
    //but I have to figure out how to get the lineItem price
    let e = {
      target: {
        name: "price",
        value: totalSum,
      },
    };
    // handleFieldChange called handleSetEstimatedPrice price 1000
    handleSetEstimatedPrice(e, totalSum);
  }, [totalSum]);

  useEffect(() => {
    //calculate total sum of all line items
    let total = 0;
    lineItems.forEach((lineItem) => {
      let included = pricingItems?.[lineItem.name]?.included || false;
      let unitPrice = pricingItems?.[lineItem.name]?.unitPrice || 0;
      let qty = pricingItems?.[lineItem.name]?.qty || 0;
      let price;
      if (pricingItems?.[lineItem.name]?.price) {
        price = parseFloat(pricingItems?.[lineItem.name]?.price);
      } else {
        price = unitPrice * qty;
      }
      total += price;
    });
    setTotalSum(total);
  }, [pricingItems]);

  useEffect(() => {
    console.log("Scenario Line Items Changed", lineItems);
    if (!pricingItems || !lineItems || initialDataSet) {
      console.log("Scenario Returning due to one of the following conditions", {
        pricingItems,
        lineItems,
        initialDataSet,
      });
    }
    console.log("Scenario Initial Data Set", initialDataSet);
    let initialData = [
      [
        {
          value: "Item",
          title: "Enter instructions here",
          className: "instructions-field",
          readOnly: true,
        },
        // { value: "Included" },
        // { value: "Instructions", className: "" }, // Apply the class
        { value: "Price", className: "dynamic-field", readOnly: true }, // Apply the class
        { value: "Qty", className: "dynamic-field", readOnly: true }, // Apply the class
        { value: "Total", className: "dynamic-field", readOnly: true }, // Apply the class
        { value: "Comments", className: "instructions-field", readOnly: true }, // Apply the class
      ],
    ];

    let myTotal = 0;
    if (myTotal < 1) {
      lineItems?.forEach((lineItem) => {
        //   if (!pricingItems[lineItem.name]) return;
        console.log("initialData Line Item", lineItem);
        console.log("initialData Pricing", pricingItems[lineItem.name]);
        // let included = pricingItems?.[lineItem.name]?.included || false;
        let unitPrice = pricingItems?.[lineItem.name]?.unitPrice || 0;
        let qty = pricingItems?.[lineItem.name]?.qty || 0;
        let price;
        if (pricingItems?.[lineItem.name]?.price) {
          price = parseFloat(pricingItems?.[lineItem.name]?.price);
        } else {
          price = unitPrice * qty;
        }
        let comment = pricingItems?.[lineItem.name]?.comments || "";

        let total = price;
        myTotal += total;
        initialData.push([
          { value: lineItem.name, readOnly: true },
          // { value: lineItem.instructions },
          // { value: included ? "Yes" : "No", readOnly: false },
          { value: unitPrice }, //readOnly: included ? true : false
          // {
          //   value: `${new Intl.NumberFormat("en-US", {
          //     style: "currency",
          //     currency: "USD",
          //   }).format(unitPrice)}`,
          // },
          { value: qty },
          {
            value: `${new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(total)}`,
            readOnly: true,
          },
          { value: comment },
        ]);
      });
    }

    // Add the total row at the bottom
    initialData.push([
      { value: "", readOnly: true },
      // { value: "", readOnly: true },
      { value: "", readOnly: true },
      { value: "", readOnly: true },
      { value: "", readOnly: true },
    ]);
    initialData.push([
      { value: "Total", readOnly: true },
      // { value: "", readOnly: true },
      { value: "", readOnly: true },
      { value: "", readOnly: true },
      // { value: "" },
      {
        value: `${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(myTotal)}`,
        className: "bg-[#FFFF00] text-black",
        readOnly: true,
      },
    ]);
    // initialData = assumptionRows(initialData);
    setData(initialData);
    setTotalSum(myTotal);
    setInitialDataSet(true); // Mark initial data as set
    // }
  }, [lineItems, pricingItems]);

  // Spreadsheet Cells
  const assumptionRows = (data) => {
    // Add 3 blank rows
    data.push([{ value: "" }, { value: "" }, { value: "" }, { value: "" }]);
    data.push([{ value: "" }, { value: "" }, { value: "" }, { value: "" }]);
    data.push([{ value: "" }, { value: "" }, { value: "" }, { value: "" }]);

    // Add a row with 4 merged cells
    data.push([
      { value: "Assumptions", component: MergedCell },
      { value: "", forceComponent: true },
      { value: "", forceComponent: true },
      { value: "", forceComponent: true },
    ]);

    return data;
  };
  const debounce = (func, delay) => {
    // console.log("<");
    return (...args) => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      debounceTimeoutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedHandlePriceChange = useCallback(
    debounce(handlePriceChange, 1000),
    [handlePriceChange]
  );

  const handleChange = (newData) => {
    let newObj = {};
    let dataChanged = false; // Flag to track if data has changed

    const updatedData = newData.map((row, rowIndex) => {
      if (rowIndex === 0) return row; // Skip header row
      // const included = row[1]?.value || "No";
      // const price = parseFloat(String(row[2]?.value).replace("$", "")) || 0;
      const price = parseFloat(String(row[1]?.value).replace("$", "")) || 0;
      const qty = parseInt(row[2]?.value, 10) || 0;
      const total = price * qty;
      const comments = row[3]?.value || "";

      // Check if any cell in the row has changed
      let rowChanged = false;
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        const cell = row[colIndex];
        const prevCell = prevDataRef.current[rowIndex]?.[colIndex];
        if (cell.value !== prevCell?.value) {
          rowChanged = true;
          dataChanged = true; // Set flag to true if any cell has changed
          console.log(`Cell changed at [${rowIndex}, ${colIndex}]:`, {
            oldValue: prevCell?.value,
            newValue: cell.value,
          });
          break; // Stop searching once a change is detected
        }
      }
      if (rowChanged) {
        console.log("Row changed:", row);
        // Create the object with the required structure
        const key = row[0].value; // Use the value from the first column as the key
        if (key === "Total") return;
        newObj = {
          type: "Standard", // hard coded
          unit: "Page", // hard coded
          unitPrice: price.toFixed(2), // calculated value
          qty: qty, // hard coded
          price: total.toFixed(2), // calculated value
          comments: comments, // user input
          // included: included === "Yes", // user input
        };

        // Call handlePriceChange with the created object
        //only call this if typing has stopped for 2 seconds
        debouncedHandlePriceChange(newObj, service, key);
      }
      console.log(
        `Row ${row[0].value} ${row[1].value} ${row[2].value} ${row[3].value} `
      );
      return [
        row[0], //item
        // row[1], //included
        row[1], //price
        row[2], //qty
        // {
        //   ...row[2],
        //   readOnly: row[1].value === "Yes" || row[1].value === true, // Make row[2] read-only if row[1] is true
        // },

        // {
        //   ...row[3],
        //   readOnly: row[1].value === "Yes" || row[1].value === true, // Make row[3] read-only if row[1] is true
        // },

        {
          value: `${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(total)}`,
          readOnly: true,
        },
        row[4],
      ];
    });

    // Ensure the next-to-last row has blank values
    updatedData[updatedData.length - 2] = [
      { value: "" },
      // { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ];

    // Update the last row with the total sum
    // Calculate the total sum of the total column
    const sheetTotal = updatedData
      .slice(1, updatedData.length - 1)
      .reduce((acc, row) => {
        const numericValue = parseFloat(row[3].value.replace(/[^0-9.-]+/g, ""));
        return acc + (isNaN(numericValue) ? 0 : numericValue);
      }, 0);

    updatedData[updatedData.length - 1] = [
      { value: "Total", readOnly: true },
      // { value: "" },
      { value: "" },
      { value: "" },

      {
        value: `${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(sheetTotal)}`,
        className: "bg-[#FFFF00]",
        readOnly: true,
      },
      { value: "" },
    ];

    // Update the previous data reference
    prevDataRef.current = newData;

    // Update the state with the modified data only if data has changed
    if (dataChanged) {
      setData(updatedData);
      setTotalSum(sheetTotal); // Update the total sum state
    }
  };
  const handleClickCell = (row, col) => {
    console.log("Cell clicked:", { row, col });
    setSelectedCell({ row, col }); // Update the selected cell's coordinates
    setFade(false); // Trigger fade out
    setTimeout(() => setFade(true), 0); // Trigger fade in
  };

  const onChangeDescription = (e) => {
    // setDescription(e.target.value);
    setDescription(e);
  };
  const onSaveDescription = (desc) => {
    setEditing(false);
    let e = {
      target: {
        name: "description",
        value: desc,
      },
    };
    console.log("onSaveDescription", e.target.name, e.target.value);
    handleChangeDescription(e);
  };
  const getScenarioDescription = (name) => {
    console.log("getScenarioDescription", name);
    console.log("getScenarioDescription", scenarios);
    let scenario = scenarios.find((s) => s.name === name);
    return scenario?.description;
  };
  return (
    <Fade unmountOnExit duration={750}>
      <div className="w-full pt-4">
        <div className="flex  items-center  mb-2">
          <div>
            {editing ? (
              <>
                <div className="flex items-center space-x-2">
                  <div className=" flex rounded-md shadow-sm w-96">
                    <ComboboxSelector
                      name={"field"}
                      options={scenarioOptions}
                      fieldType={"single"}
                      open={true}
                      handleSelect={(v) => {
                        setSelectedScenario(v);
                        setDescription(v);
                        onSaveDescription(v);
                      }}
                      // handleSelect(v, service, field)}
                      handleDeselect={(v) => setSelectedScenario(v)}
                      // handleDeselect}
                      handleClearAllSelections={
                        (value) =>
                          console.log("handleClearAllSelections", value)
                        // handleClearAllSelections(value, service, field)
                      } //{handleClearAllSelections}
                      // value={opts2[field] ? opts2[field] : null}
                      value={selectedScenario}
                    />
                  </div>

                  {/* <Input
                  id="description"
                  name="description"
                  placeholder="Short description"
                  value={description}
                  onChange={(e) => {
                    onChangeDescription(e);
                  }}
                /> */}
                  <Icon
                    icon="mdi:content-save-edit-outline" //save icon
                    className="cursor-pointer text-slate-500 hover:text-slate-700 h-6 w-6"
                    onClick={() => onSaveDescription(description)}
                  />
                </div>
              </>
            ) : (
              <div className="flex flex-col  ">
                <h2 className="flex items-center">
                  {description}
                  <Icon
                    icon="mdi:pencil"
                    className="ml-1 cursor-pointer text-slate-500 hover:text-slate-700"
                    onClick={() => setEditing(true)}
                  ></Icon>{" "}
                  <div>
                    {index === 0 ? (
                      <span className="text-sm"></span>
                    ) : (
                      <div className="ml-4">
                        <Button color="link" handleClick={handleDeleteOption}>
                          Delete option
                        </Button>
                      </div>
                    )}
                  </div>
                </h2>
                <div className="flex items-center ">
                  <span className="text-sm">
                    {getScenarioDescription(selectedScenario)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        {rowInstructions !== null ? (
          <Fade in={fade} out={!fade} unmountOnExit duration={2000}>
            <Tip color="secondary" className="instructions">
              <span className="inline-flex">
                <strong className="mr-2"> {selectedLineItem}: </strong>{" "}
                {rowInstructions}
              </span>
            </Tip>
          </Fade>
        ) : (
          <Fade in={fade} out={!fade} unmountOnExit duration={2000}>
            <Tip color="secondary" className="instructions">
              <span className="inline-flex">
                Click on each row to see instructions for that row. Please be
                sure to follow these instruction so your bid is not
                disqualified.
              </span>
            </Tip>
          </Fade>
        )}
        <Spreadsheet
          id="quote"
          data={data}
          onChange={handleChange}
          onSelect={handleClickCell}
          className="w-full"
        />
        {/* <div className="py-6">
          <Spreadsheet id="exclusions" data={[]} />
        </div> */}
        {/* <div className="py-6">
          <Spreadsheet id="price-list" data={[]} />
        </div> */}
      </div>
    </Fade>
  );
};

export default SpreadsheetComponent;
