import { Fragment, useEffect, useMemo, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { Label } from "../../../components/Typography/FieldLabel";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import JurisdictionInput from "./JurisdictionInput";
import {
  Checkboxes,
  Dropdown,
  RadioGroupSingle,
  Section,
  SectionDivider,
  TextArea,
  TextInput,
} from "./DataEntry";
import Tip from "../../../components/Tip";

export default function ClassActionInfo({
  create,
  newProject,
  languageOptions,
  project,
  onFieldChange,
  handleSelectedLanguage,
  handleDeselectedLanguage,
  handleMultiDeSelectNew,
  handleClearAllSelections,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  handleSingleSelect,
  handleMultiSelect,
  handleMultiDeSelect,
  field,
  service,
  scope,
  isChecked,
  currentProject,
}) {
  const [selectedJurisdictions1, setSelectedJurisdictions1] = useState([]);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [opts2, setOpts2] = useState({});

  //   Case Name:
  // Class size:
  // Gross settlement amount:
  // All-in or claims made:
  // Notice page length:
  // Will a website be needed?
  // Will translation services be needed:
  // Will a 1-800 number be needed:
  // What will happen to uncashed funds:
  // Disbursement type (i.e., check, ACH, digital disbursement):
  // Will 1099s and/or W2s be needed?

  useEffect(() => {
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ]?.opts2
    ) {
      let opts =
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2;
      setOpts2({ ...opts });
      // setOpts2(
      //   newProject.services[
      //     newProject.services.findIndex((el) => el.service === service)
      //   ].opts2
      // );
    }
    console.log("createSolicitationFromTemplate", opts2);
  }, [newProject]);

  useEffect(() => {
    console.log("ClassAction Opts2 changed", opts2);
  }, [opts2]);

  return (
    <div className="mt-8">
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {create && create == true && (
          <div className=" col-span-full bg-[#f6f8ff] border border-brand-200 rounded p-2 pb-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <Section label="Assumptions" create={create} />
            <Tip colSpan={"full"} noMargin>
              <div className="inline">
                The fields in the assumptions section are required. It is
                extremely important to fill out the Assumptions section to get
                simple, accurate quotes.
                <span className="ml-1 font-medium">
                  We've provided you with some default answers.
                </span>
              </div>
            </Tip>
            <div id="assumption-list" className="col-span-full space-y-10">
              <TextInput
                label="Class Size"
                // tooltip={{
                //   title: "Class size",
                //   text: "Letting the vendor know how many people were affected will help them determine how many letters will need to be mailed.",
                // }}
                service={service}
                field="number_of_affected_individuals"
                defaultValue={newProject.number_of_affected_individuals || null}
                // type="size"
                create={create}
                opts2={opts2}
                placeholder=""
                onFieldChange={onFieldChange}
                required
              />
              <TextInput
                label="Gross settlement amount"
                // tooltip={{
                //   title: "Data Volume",
                //   text: "Letting the vendor know the data volume will help them tailor their quote to your needs.",
                // }}
                service={service}
                field="gross_settlement_amount"
                // type="size"
                create={create}
                opts2={opts2}
                placeholder=""
                onFieldChange={onFieldChange}
                prefix="$"
                indicator="USD"
                // defaultValue={
                //   opts2.gross_settlement_amount ? opts2.gross_settlement_amount : 2
                // }
              />
              {/* <TextInput
                label="All-in or claims made"
                tooltip={{
                  title: "All-in or claims made",
                  text: "Letting the vendor know the data volume will help them tailor their quote to your needs.",
                }}
                service={service}
                field="all_in_or_claims_made"
                create={create}
                opts2={opts2}
                placeholder=""
                onFieldChange={onFieldChange}
              /> */}
              <RadioGroupSingle
                cols={4}
                rows={1}
                type="single"
                label="All-in or claims made"
                description="Will the vendor be providing all-in or claims made services?"
                // tooltip={{
                //   title: "Text Color",
                //   text: "Most vendors will default to quoting black and white print.  If you need color, be sure to specify that so that all vendors use that when building the price.",
                // }}
                service={service}
                field="all_in_or_claims_made"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                open={true}
                newProject={newProject}
                handleSelect={handleSingleSelect}
                // defaultValue={"black_and_white"}
                options={[
                  {
                    id: 1,
                    title: "All-in",
                    description: "",
                    value: "All in",
                  },
                  {
                    id: 2,
                    title: "Claims Made",
                    description: "",
                    value: "Claims Made",
                  },
                ]}
              />
              <TextInput
                label="Notice page length"
                // tooltip={{
                //   title: "Data Volume",
                //   text: "Letting the vendor know the data volume will help them tailor their quote to your needs.",
                // }}
                service={service}
                field="notice_page_length"
                // type="size"
                create={create}
                opts2={opts2}
                placeholder=""
                onFieldChange={onFieldChange}
                // indicator="pages"
                // defaultValue={
                //   opts2.notice_page_length ? opts2.notice_page_length : 2
                // }
              />
              {/* <Dropdown
                label="Required Languages"
                subLabel="What language will the letters be written?"
                tooltip={{
                  title: "Call Center Languages",
                  text: "Staffing call centers with agents who speak multiple languages can be costly, so it is important to make this part of your request so that all vendors are consistent in their bidding..",
                }}
                service={service}
                field="required_language"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="multiSelect"
                open={true}
                defaultValue={["English"]}
                newProject={newProject}
                handleSelect={handleMultiSelect}
                handleDeselect={(value) =>
                  handleMultiDeSelect(value, service, "required_language")
                }
                handleDeselectedLanguage={(value) =>
                  handleMultiDeSelect(value, service, "required_language")
                }
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(value, service, "required_language")
                }
                options={languageOptions ? languageOptions : []}
              /> */}
              <Checkboxes
                label="Website"
                description="Check this box if a website will be needed."
                // tooltip={{
                //   title: "Handling Return Mail",
                //   text: (
                //     <div className="inline">
                //       <div className="mb-4">
                //         Sometimes vendors will quote a price that does not
                //         include handling undeliverable or returned mail.
                //         Requiring this ensures that all vendors use the same
                //         assumptions when quoting the work.
                //       </div>
                //     </div>
                //   ),
                // }}
                service={service}
                field="website_needed"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                newProject={newProject}
                onFieldChange={onFieldChange}
                // onChange={(e) => {
                //   onFieldChange(
                //     "options",
                //     //if checked, true, else false
                //     e,

                //     "return_mail",
                //     "mailings"
                //   );
                // }}
                options={[]}
                defaultChecked={
                  opts2.website_needed ? opts2.website_needed : true
                }
              />
              <Checkboxes
                label="Translation Services"
                description="Check this box if translation services will be needed."
                // tooltip={{
                //   title: "NCOA",
                //   text: (
                //     <div className="inline">
                //       <div className="mb-4">
                //         National Change of Address (NCOALink) is a secure
                //         dataset of approximately 160 million permanent
                //         change-of-address (COA) records.
                //       </div>
                //     </div>
                //   ),
                // }}
                service={service}
                field="translation_services"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                newProject={newProject}
                onFieldChange={onFieldChange}
                // onChange={(e) => {
                //   onFieldChange(
                //     "options",
                //     //if checked, true, else false
                //     e,

                //     "return_mail",
                //     "mailings"
                //   );
                // }}
                options={[]}
                defaultChecked={
                  opts2.translation_services ? opts2.translation_services : true
                }
              />
              <Checkboxes
                label="1-800 Number"
                description="Check this box if a 1-800 number will be needed."
                // tooltip={{
                //   title: "NCOA",
                //   text: (
                //     <div className="inline">
                //       <div className="mb-4">
                //         National Change of Address (NCOALink) is a secure
                //         dataset of approximately 160 million permanent
                //         change-of-address (COA) records.
                //       </div>
                //     </div>
                //   ),
                // }}
                service={service}
                field="one_eight_hundred_number"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                newProject={newProject}
                onFieldChange={onFieldChange}
                // onChange={(e) => {
                //   onFieldChange(
                //     "options",
                //     //if checked, true, else false
                //     e,

                //     "return_mail",
                //     "mailings"
                //   );
                // }}
                options={[]}
                defaultChecked={
                  opts2.one_eight_hundred_number
                    ? opts2.one_eight_hundred_number
                    : true
                }
              />
              <Checkboxes
                label="1099 and/or W2"
                description="Check this box if a 1099 or W-2 will be needed."
                // tooltip={{
                //   title: "NCOA",
                //   text: (
                //     <div className="inline">
                //       <div className="mb-4">
                //         National Change of Address (NCOALink) is a secure
                //         dataset of approximately 160 million permanent
                //         change-of-address (COA) records.
                //       </div>
                //     </div>
                //   ),
                // }}
                service={service}
                field="1099s_and_w2s"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                newProject={newProject}
                onFieldChange={onFieldChange}
                // onChange={(e) => {
                //   onFieldChange(
                //     "options",
                //     //if checked, true, else false
                //     e,

                //     "return_mail",
                //     "mailings"
                //   );
                // }}
                options={[]}
                defaultChecked={
                  opts2["1099s_and_w2s"] ? opts2["1099s_and_w2s"] : true
                }
              />
              {/* Text Area for Uncashed Funds */}
              <TextArea
                label="Uncashed Funds"
                description="What will happen to uncashed funds?"
                // tooltip={{
                //   title: "Data Volume",
                //   text: "Letting the vendor know the data volume will help them tailor their quote to your needs.",
                // }}
                service={service}
                field="uncashed_funds"
                create={create}
                newProject={newProject}
                opts2={opts2}
                placeholder=""
                onFieldChange={onFieldChange}
                required
              />
              <RadioGroupSingle
                cols={4}
                rows={1}
                type="multiSelect"
                label="Disbursement Type"
                description="How will funds be disbursed? Check all that apply."
                // tooltip={{
                //   title: "Text Color",
                //   text: "Most vendors will default to quoting black and white print.  If you need color, be sure to specify that so that all vendors use that when building the price.",
                // }}
                service={service}
                field="disbursement_type"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                open={true}
                newProject={newProject}
                handleSelect={handleMultiSelect}
                // defaultValue={[
                //   // "project_confirmation",
                //   // "printing_confirmation",
                //   "mailing_confirmation",
                //   "exceptions",
                // ]}
                options={[
                  {
                    id: 1,
                    title: "Check",
                    description: "",
                    value: "check",
                  },
                  {
                    id: 2,
                    title: "ACH",
                    description: "",
                    value: "ach",
                  },
                  {
                    id: 3,
                    title: "Digital Disbursement",
                    description: "",
                    value: "digital_disbursement",
                  },
                  {
                    id: 4,
                    title: "Other",
                    description: "",
                    value: "other",
                  },
                ]}
              />
              {/* <TextArea for other disbursement type */}
              {/* <RadioGroupSingle
                cols={4}
                rows={1}
                type="single"
                label="1099's and/or W2's"
                description="Will 1099's or W2's be required?"
                service={service}
                field="1099s_and_w2s"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                open={true}
                newProject={newProject}
                handleSelect={handleSingleSelect}
                // defaultValue={"black_and_white"}
                options={[
                  {
                    id: 1,
                    title: "Both",
                    description: "",
                    value: "both",
                  },
                  {
                    id: 2,
                    title: "1099 Only",
                    description: "",
                    value: "1099_only",
                  },
                  {
                    id: 3,
                    title: "W2 Only",
                    description: "",
                    value: "w2_only",
                  },
                  {
                    id: 4,
                    title: "Neither",
                    description: "",
                    value: "neither",
                  },
                ]}
              /> */}
              {/* <RadioGroupSingle
                cols={4}
                rows={1}
                type="multiSelect"
                label="1099s and/or W2s"
                description="Will 1099s and/or W2s be needed?"
                tooltip={{
                  title: "Text Color",
                  text: "Most vendors will default to quoting black and white print.  If you need color, be sure to specify that so that all vendors use that when building the price.",
                }}
                service={service}
                field="1099s_and_w2s"
                create={create}
                opts2={opts2}
                placeholder=""
                fieldType="single"
                open={true}
                newProject={newProject}
                handleSelect={handleMultiSelect}
                defaultValue={[
                  // "project_confirmation",
                  // "printing_confirmation",
                  "1099s_and_w2s",
                  "exceptions",
                ]}
                options={[
                  {
                    id: 1,
                    title: "Project Confirmation",
                    description: "",
                    value: "project_confirmation",
                  },
                  {
                    id: 2,
                    title: "Printing Confirmation",
                    description: "",
                    value: "printing_confirmation",
                  },
                  {
                    id: 3,
                    title: "Mail Confirmation",
                    description: "",
                    value: "mailing_confirmation",
                  },
                  {
                    id: 4,
                    title: "Exceptions",
                    description: "",
                    value: "exceptions",
                  },
                ]}
              /> */}
            </div>
          </div>
        )}

        {/* This is used if there are additional non-assumption requrements. Comment out for now.
        {create && (
          <div className="col-span-6 text-xl font-medium text-slate-900">
            Other Requirements
          </div>
        )}

        {create && (
          <Tip colSpan={"full"} noMargin>
            The rest of this form is optional but we highly recommend completing
            it to the best of your ability. These are the requirements that you
            will expect vendors to meet. Giving them all the information up
            front will ensure that you get the most accurate quotes.
          </Tip>
        )} */}
        {/* {!create && (
          <Tip colSpan={"full"} noMargin>
            If you bill for any of these requirements, use the "Other Fees" line
            item.
          </Tip>
        )} */}
        {/* <div className="sm:col-span-6 grid grid-cols-6">
        Other requirements go here.
        </div> */}

        {/* <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            bold
            sm
            htmlFor="required_languages"
            className="my-auto col-span-6 block  text-slate-700"
          >
            Required languages
          </Label>
          {!create && (
            <div className="col-span-full mb-1">
              <ReadOnlyAnswer
                answer={
                  opts2 &&
                  opts2.required_language &&
                  opts2.required_language.length > 0
                    ? opts2.required_language
                    : "No requirement provided"
                }
                type={
                  opts2.required_language && opts2.required_language.length > 0
                    ? "chips"
                    : "text"
                }
              />
            </div>
          )}
          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="required_langages"
                options={languageOptions ? languageOptions : []}
                fieldType="multiSelect"
                open={true}
                handleSelect={(v) =>
                  handleSelectedLanguage(v, "mailings", "required_language")
                }
                // handleDeselect={handleDeselectedLanguage}
                handleDeselect={(value) =>
                  handleMultiDeSelectNew(value, "mailings", "required_language")
                }
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(value, service, "required_language")
                }
                // handleClearAllSelections={handleClearAllSelections} //{handleClearAllSelections}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.required_language
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.required_language
                    : [];
                }}
                // value={
                //   () => {
                //     console.log(
                //       "opts2.required_language",
                //       newProject &&
                //         newProject.services[
                //           newProject.services.findIndex(
                //             (el) => el.service === service
                //           )
                //         ].opts2.required_language
                //     );
                //     // console.log("opts2.required_language", opts2);
                //     return (
                //       (newProject &&
                //         newProject.services[
                //           newProject.services.findIndex(
                //             (el) => el.service === service
                //           )
                //         ].opts2.required_language) ||
                //       []
                //     );
                //   }
                //   // opts2.required_language ? opts2.required_language : ["English"]
                // }
              />
            </div>
          )}
        </div> */}

        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Final Details
          </h3>
          {create && (
            <Paragraph>
              Use the below field to provide any additional details about your
              project or bid request that you think might be helpful to the
              vendor.
            </Paragraph>
          )}
          <div className="mt-1">
            {create ? (
              <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",
                    //if checked, true, else false
                    e,

                    "additional_details",
                    "class_action",

                    null
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            ) : (
              <ReadOnlyAnswer
                answer={opts2 && opts2.additional_details}
                type="text"
              />
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
